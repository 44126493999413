@tailwind base;
@tailwind components;
@tailwind utilities;

.back {
    stroke: gray;
    stroke-width:  10;
  }
  
.front {
    stroke: black;
    stroke-width: 10;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: center;
    animation: fill 2s reverse;
  }

  @keyframes fill {
    to { stroke-dasharray: 0,1000000; }
}


@keyframes display {
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}